import { createReducer, on } from '@ngrx/store';
import { CommercialUser } from '../../shared/models/commercial-user.model';
import { setUser } from './auth.actions';

export interface AuthState {
  user$: CommercialUser | null;
}

const initialState: AuthState = {
  user$: null,
};

export const authReducer = createReducer(
  initialState,
  on(setUser, (state, { user$ }) => ({ ...state, user$ })),
);