import { Routes } from '@angular/router';
import { authGuardFn } from '@auth0/auth0-angular';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: "error",
    redirectTo: "unauthorised",
    pathMatch: "full"
  },
  {
    path: 'admin',
    loadComponent: () => import('./admin/admin.component').then((c) => c.AdminComponent),
    canActivate: [authGuardFn],
  },
  {
    path: 'dashboard',
    loadComponent: () => import('./dashboard/dashboard.component').then((m) => m.DashboardComponent),
    canActivate: [authGuardFn],
  },
  {
    path: 'plans',
    loadChildren: () => import('./plan/plan.module').then((m) => m.PlanModule),
    canActivate: [authGuardFn],
  },
  {
    path: 'debug',
    loadComponent: () => import('./base/components/debug/debug.component').then((m) => m.DebugComponent),
    canActivate: [authGuardFn],
  },
  {
    path: 'sign-off-response',
    loadComponent: () => import('./base/components/sign-off-response/sign-off-response.component').then((m) => m.SignOffResponseComponent),
    canActivate: [authGuardFn],
  },
  {
    path: 'unauthorised',
    loadComponent: () => import('./base/components/unauthorised/unauthorised.component').then((m) => m.UnauthorisedComponent)
  },
];
