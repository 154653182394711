import { HttpInterceptorFn } from '@angular/common/http';
import { inject, Injector } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { CommercialService } from '../../services/commerical.service';

export const acceptJsonInterceptor: HttpInterceptorFn = (req, next) => {
  const injector = inject(Injector);
  const commercialService = injector.get(CommercialService);

  return commercialService.getToken().pipe(
    switchMap(token => {
      const cloned = req.clone({
        setHeaders: {
          Accept: 'application/json',
          Authorization: `Bearer ${token}`
        }
      });
      return next(cloned);
    })
  );
};
