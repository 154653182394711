import { ApplicationConfig } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideAuth0 } from '@auth0/auth0-angular';
import { environment as env } from '../environments/environment';
import { provideStore } from '@ngrx/store';
import { authReducer } from './store/authentication/auth.reducer';
import { provideEffects } from '@ngrx/effects';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { acceptJsonInterceptor } from './shared/interceptors/accept-json.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideAuth0({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
    provideRouter(routes),
    provideAnimationsAsync(),
    provideStore({ auth: authReducer }), 
    provideEffects([]),
    provideHttpClient(withInterceptors([acceptJsonInterceptor])),
  ],
};
